import { useEffect, useRef } from "react";
import SpatialNavigation from "spatial-navigation-js";
import { useDispatch } from "react-redux";

const useSpatialNavigationWatch = (sections) => {
  const isSNInitialized = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let cleanupFunction = () => {}; // Placeholder for cleanup function

    if (!isSNInitialized.current) {
      SpatialNavigation.init();

      sections.forEach((section) => {
        SpatialNavigation.add({
          id: `watch-${section.id}`,
          selector: `#${section.id} .focusable`,
          defaultElement: `#${section.id} .focusable.active`,
          enterTo: "last-focused",
          restrict: "self-first",
          straightOnly: false,
          leaveFor: {
            up: "#upperbox .focusable:first-child", // Focus the first element in upperbox when navigating up
          },
        });
      });

      SpatialNavigation.makeFocusable();
      SpatialNavigation.focus(`watch-${sections[0].id}`); // Focus first section by default

      isSNInitialized.current = true;

      // Define cleanup function to remove event listener and SpatialNavigation entries
      cleanupFunction = () => {
        sections.forEach((section) => {
          SpatialNavigation.remove(`watch-${section.id}`);
        });
        // Optionally reset focus or do other cleanup as needed
        isSNInitialized.current = false;
      };
    }

    // Cleanup on component unmount
    return () => {
      cleanupFunction();
    };
    // eslint-disable-next-line
  }, [dispatch]);
};

export default useSpatialNavigationWatch;
