import React, { useState, useEffect, useRef, useCallback } from "react";
import background from "../../assets/Black-50-transparent.png";
import { PlayFill, PauseFill, VolumeMute, VolumeUp } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { selectApp, setCurrentWatch } from "../appSlice";

const PlaybackControls = ({ onPlayPause, playerRef }) => {
  const { isPlaying, isPlayerReady, isMuted, isFocusedOnControls, showSidebar } = useSelector(selectApp).currentWatch;
  const [showControls, setShowControls] = useState(false);
  const hideControlsTimeoutRef = useRef(null);

  // Refs for buttons
  const playPauseButtonRef = useRef(null);
  const muteButtonRef = useRef(null);
  const lastFocusedElement = useRef(null); // Ref to store last focused element

  const dispatch = useDispatch();

  const resetHideControlsTimeout = useCallback(() => {
    const timeout = 5000;
    setShowControls(true); // Show the controls
    clearTimeout(hideControlsTimeoutRef.current);
  
    hideControlsTimeoutRef.current = setTimeout(() => {
      lastFocusedElement.current = document.activeElement; // Store the currently focused element
      setShowControls(false); // Hide the controls after the timeout
      dispatch(setCurrentWatch({ showSidebar: false, isFocusedOnControls: false }));
    }, timeout);
  }, [dispatch]);
  
  // Show/Hide Controls logic
  useEffect(() => {
    if(isFocusedOnControls)
      resetHideControlsTimeout();

    return () => {
      clearTimeout(hideControlsTimeoutRef.current);
    };
  }, [resetHideControlsTimeout, isFocusedOnControls, showControls, showSidebar]);

  useEffect(() => {
    if (showControls && lastFocusedElement.current) {
      lastFocusedElement.current.focus(); // Restore focus to the last focused element when controls are shown
    }
  }, [showControls]);
  
  useEffect(() => {
    if (showControls && isFocusedOnControls && playPauseButtonRef.current) {
      playPauseButtonRef.current.focus();
    }
  }, [showControls, isFocusedOnControls]);
  

  const toggleMute = useCallback(async () => {
    const player = playerRef.current;
    if (player) {
      if (isMuted) {
        await player.setVolume(100);
        await player.unMute();
        dispatch(setCurrentWatch({ isMuted: false, volume: 100 }));
      } else {
        await player.setVolume(0);
        await player.mute();
        dispatch(setCurrentWatch({ isMuted: true, volume: 0 }));
      }
    }
  }, [dispatch, isMuted, playerRef]);

  // Handle keydown for play/pause, mute, and focus playPauseButton on ArrowUp
  useEffect(() => {
    const handleKeyDown = (event) => {
      const focusedElement = document.activeElement;

      // If focus is on controls, handle navigation between buttons
      if (isFocusedOnControls) {
        switch (event.key) {
          case "ArrowLeft":
            if (focusedElement === muteButtonRef.current) {
              playPauseButtonRef.current.focus(); // Move focus to play/pause button
            }
            break;
          case "ArrowRight":
            if (focusedElement === playPauseButtonRef.current) {
              muteButtonRef.current.focus(); // Move focus to mute button
            }
            break;
          case "Enter":
            if (focusedElement === playPauseButtonRef.current) {
              onPlayPause();
            } else if (focusedElement === muteButtonRef.current) {
              toggleMute();
            }
            break;
          default:
            break;
        }
        return;
      }

      // If focus is not on controls, handle ArrowUp for showing controls and focusing play/pause button
      if ((event.key === "Enter" || event.keyCode === 13) && !showSidebar) {
        setShowControls(true); // Ensure the controls are shown
        setTimeout(() => {
          playPauseButtonRef.current.focus(); // Focus the play/pause button
          dispatch(setCurrentWatch({ isFocusedOnControls: true }));
        }, 0);
        resetHideControlsTimeout();
      }
    };

    if (isPlayerReady) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onPlayPause, toggleMute, resetHideControlsTimeout, isPlayerReady, isFocusedOnControls, showSidebar, dispatch]);

  return (
    <div
      className={`d-flex flex-column justify-content-center overflow-hidden ${
        showControls && isFocusedOnControls ? "" : "d-none"
      } control`}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="d-flex justify-content-center">
        {/* Play/Pause Button */}
        <button
          ref={playPauseButtonRef}
          className="focusable btn btn-dark p-2 m-2 d-flex justify-content-center"
          onFocus={() => dispatch(setCurrentWatch({isFocusedOnControls: true}))}
          onBlur={() => dispatch(setCurrentWatch({isFocusedOnControls: false}))}
        >
          {isPlaying ? (
            <PauseFill color="royalblue" size={25} />
          ) : (
            <PlayFill color="orange" size={25} />
          )}
        </button>

        {/* Mute Button */}
        <button
          ref={muteButtonRef}
          className="focusable btn btn-dark p-2 m-2 d-flex justify-content-center"
          onFocus={() => dispatch(setCurrentWatch({isFocusedOnControls: true}))}
          onBlur={() => dispatch(setCurrentWatch({isFocusedOnControls: false}))}
        >
          {isMuted ? (
            <VolumeMute color="royalblue" size={25} />
          ) : (
            <VolumeUp color="royalblue" size={25} />
          )}
        </button>
      </div>
    </div>
  );
};

export default PlaybackControls;
