import React, { memo } from "react";
import CategoryRow from "../CategoryRow/CategoryRow";
import "./GridVideos.css";
import FilterBadges from "../FilterBadges";
import { useSelector } from "react-redux";
import { selectApp } from "../appSlice";

const GridVideos = memo(({ categories, spatialElementId, slideView, filterEnabled }) => {
  const selectedCategory = useSelector(selectApp).selectedFilterCategory;

  return (
    <div id={spatialElementId} className="categoryList" >
      {filterEnabled && <FilterBadges data={categories} /> }
      {filterEnabled ? (
        // Show categories in separate rows
        categories.map((category, index) => (
          <CategoryRow
            key={index}
            data={category}
            cIndex={index}
            slideView={slideView}
            hidden={!slideView && category.category_tamil !== selectedCategory}
            filterEnabled={filterEnabled}
          />
        ))
      ) : (
        // Show all categories in a single row/grid
        <CategoryRow
          key={0}
          data={{
            category_id: 12,
            category_name: "Kids",
            category_tamil: "Kids",
            category_name_tamil: "குழந்தைகள்",
            series: [].concat(...categories.map(category => category.series)) // Merging all series into one row
          }}
          slideView={slideView}
          cIndex={0}
          filterEnabled={filterEnabled}
        />
      )}
    
    </div>
  );
});

export default GridVideos;
