import { useEffect } from "react";
import SpatialNavigation from "spatial-navigation-js";

const useSpatialNavigation = () => {
  useEffect(() => {
    let cleanupFunction = () => {};

    SpatialNavigation.init(); // Initialize Spatial Navigation

    // Configure Spatial Navigation for `middlebox`
    SpatialNavigation.add({
      id: "middlebox",
      selector: "#middlebox .focusable",
      enterTo: "last-focused",
      straightOnly: true,
      restrict: "self-only",
      leaveFor: {
        up: "@upperbox", // Assuming you want to focus on an element in `upperbox`
      },
    });

    SpatialNavigation.makeFocusable(); // Enable focusability for elements
    SpatialNavigation.focus("middlebox"); // Focus on `middlebox`

    // Cleanup function to remove event listeners and reset Spatial Navigation
    cleanupFunction = () => {
      SpatialNavigation.remove("middlebox");
    };

    return cleanupFunction;
  }, []); // Re-run if rowRefs or middleboxRef changes
};

export default useSpatialNavigation;
