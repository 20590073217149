import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { throttle } from "lodash";
import CategoryRow from "../CategoryRow/CategoryRow";
import "./ListCategories.css";

const ListCategories = ({ categories, spatialElementId, slideView }) => {
  const categoryListRef = useRef(null); // Ref for the middlebox wrapper
  const categoryRowRefs = useRef([]); // Array of refs for each category row
  const currentRowIndex = useRef(0); // Track the current row index
  const isAnimating = useRef();

  // Scroll to a specific row based on index, with throttling
  const scrollToRow = throttle((event) => {
    let duration = event.repeat ? 0 : isAnimating.current ? 0.3 : 0.5;

    const targetRow = categoryRowRefs.current.find(
      (element) => element === document.activeElement.closest(".category-row")
    );

    if (targetRow && categoryListRef.current) {
      const maxY = -(categoryListRef.current.scrollHeight - categoryListRef.current.clientHeight);
      let newY = -targetRow.offsetTop;
      const clampedY = Math.max(Math.min(newY, 0), maxY); // Clamp newY between 0 and maxY

      const middlebox = document.getElementById("middlebox");

      gsap.to(middlebox, {
        y: clampedY,
        duration: duration,
        ease: "power3.out",
        onStart: () => (isAnimating.current = true),
        onComplete: () => (isAnimating.current = false),
        overwrite: true,
      });
    }
  }, 0);

  // Handle key navigation for vertical scrolling
  useEffect(() => {
    isAnimating.current = false;
    const handleKeyDown = (event) => {
      if (!document.activeElement.closest('#middlebox')) return;

      if (event.key === "ArrowDown" || event.keyCode === 40) {
        if (currentRowIndex.current < categories.length - 1) {
          currentRowIndex.current += 1;
          scrollToRow(event);
        }
      } else if (event.key === "ArrowUp" || event.keyCode === 38) {
        if (currentRowIndex.current > 0) {
          currentRowIndex.current -= 1;
          scrollToRow(event);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [categories.length, scrollToRow]);

  return (
    <div
      className="middlebox-wrapper p-2"
      ref={categoryListRef}
      style={{ overflow: "hidden", position: "relative" }}
    >
      <div id={spatialElementId} className="categoryList">
        {categories.map((category, index) => (
          <CategoryRow
            key={`${category.category_id}-${index}`} // Ensure unique key by combining category_id with index
            data={category}
            slideView={slideView}
            cIndex={index}
            ref={(el) => (categoryRowRefs.current[index] = el)} // Assign each row its unique ref
            hidden={false}
          />
        ))}
      </div>
    </div>
  );
};

export default ListCategories;
