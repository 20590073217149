import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./Navbar.css";
import logo from "../../assets/icons8-play-100.png";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import useSpatialNavigation from "./SpatialNavigationWrapperNavbar";
import { Film, HouseDoorFill, MusicNoteBeamed, PersonArmsUp, Tv } from "react-bootstrap-icons";

const NavBar = () => {
  const [selectedTab, setSelectedTab] = useState("Home");
  const navItemRefs = useRef([]);
  const history = useHistory();
  const location = useLocation(); // Use location to track the current path

  useSpatialNavigation();

  const menu = useMemo(() => [
    { id: "Home", label: "Home", path: "/", icon: <HouseDoorFill size={25} /> },
    { id: "Shows", label: "Shows", path: "/shows", icon: <Tv size={25} /> },
    { id: "Movies", label: "Movies", path: "/movies", icon: <Film size={25} /> },
    { id: "Songs", label: "Songs", path: "/songs", icon: <MusicNoteBeamed size={25} /> },
    { id: "Kids", label: "Kids", path: "/kids", icon: <PersonArmsUp size={25} /> },
  ], []);
  
  // Update the selected tab based on the current path whenever it changes
  useEffect(() => {
    const currentTab = menu.find(item => item.path === location.pathname);
    if (currentTab) {
      setSelectedTab(currentTab.id);
      // Focus the corresponding tab element
      const tabIndex = menu.findIndex(item => item.id === currentTab.id);
      if (navItemRefs.current[tabIndex]) {
        navItemRefs.current[tabIndex].focus();
      }
    }
  }, [location.pathname, menu]);

  const handleTabClick = useCallback((item) => {
    setSelectedTab(item.id); // Update selected tab
    history.push(item.path); // Navigate to the selected path
  }, [history]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        const focusedElement = document.activeElement;
        const index = navItemRefs.current.findIndex(ref => ref === focusedElement);
        if (index !== -1) {
          handleTabClick(menu[index]);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleTabClick, menu]);

  return (
    <div className="header">
      <div id="upperbox" className="tabBar row justify-content-center">
        <div className="col-2 align-self-center d-flex align-items-center">
          <img
            src={logo}
            alt="TamilOdai.com"
            style={{ maxHeight: 67, display: "inline" }}
          />
          <span className="h4 fw-bold ms-2">தமிழ் ஓடை</span>
        </div>
        <div className="col-8">
          <div className="d-flex justify-content-center">
            {menu.map((item, index) => (
              <Link                 
                key={index} 
                to={item.path}
                ref={(el) => (navItemRefs.current[index] = el)}
                onClick={() => handleTabClick(item)}
                tabIndex="0"
                className={`tabButton focusable fw-bold ${
                  selectedTab === item.id ? "active" : ""
                }`}>
                  {item.icon}
              </Link>                
            ))}
          </div>
        </div>
        <div className="col-2"></div>
      </div>
    </div>
  );
};

export default NavBar;
