import React, { useEffect } from "react";
import "./Watch.css";
import { useDispatch, useSelector } from "react-redux";
import useSpatialNavigationWatch from "./SpatialNavigationWrapperWatch";
import Loading from "../Loading";
import SpatialNavigation from "spatial-navigation-js";
import { useHistory } from "react-router-dom";
import { fetchDataAsync, selectApp, setCurrentWatch } from "../appSlice";

const Watch = ({playerRef}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  //const { isLoading, status } = useSelector(selectApp);
  const sections = [{ id: "sidebar" }, { id: "playbackControls" }];
  const { isPlayerReady, series } = useSelector(selectApp).currentWatch;
  useSpatialNavigationWatch(sections);

  useEffect(() => {
    SpatialNavigation.disable("middlebox-movies");
    SpatialNavigation.disable("middlebox");

    SpatialNavigation.enable("watch-sidebar");
    SpatialNavigation.enable("playbackControls");

    const handleKeyDown = (event) => {
      if (event.keyCode === 10009) {
        history.goBack();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      dispatch(setCurrentWatch({ isPlayerReady: false, isPlaying: false }));
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, history, playerRef]);

  useEffect(() => {
    const fetchDataFromApi = async () => {
      if (series) {
        try {
          const result = await dispatch(
            fetchDataAsync(`watch.php?series_id=${series.series_id}`)
          );
          dispatch(setCurrentWatch({ data: result }));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchDataFromApi();

  }, [dispatch, series]);

  return (
    <div className="main-container">
      {/* Loading screen is displayed when the player isn't ready yet */}
      {(!isPlayerReady) && <Loading />}

      {/* <h1>{isLoading.toString()} - {isPlayerReady.toString()} - {isPlaying.toString()}</h1> */}

      {/* {isPlayerReady && <Sidebar selectedEpisode={selectedEpisode} />} */}
      {/* {status === "succeeded" && (
        <div>
          <div id="center-container" className="d-flex flex-column">
            <VideoPlayer />
          </div>
        </div>
      )}
      {status === "failed" && <p>Error loading data</p>} */}
    </div>
  );
};

export default Watch;
