import React, { useRef, useEffect, forwardRef } from "react";
import { gsap } from "gsap";
import './CategoryRow.css';
import EpisodeCard from "../EpisodeCard/EpisodeCard";
import { throttle } from "lodash";

const CategoryRow = forwardRef(({ data, slideView, filterEnabled, hidden }, ref) => {
  const seriesListRef = useRef(null);
  const episodeRefs = useRef([]); // Array of refs for each episode in sequential order
  const isAnimating = useRef(false);

  // Function to handle horizontal scrolling
  const scrollToEpisode = throttle((targetIndex) => {
    const targetEpisode = episodeRefs.current[targetIndex];
    if (!targetEpisode || !seriesListRef.current) return;

    const seriesList = seriesListRef.current;
    const newX = -targetEpisode.offsetLeft;
    const maxScrollLeft = -(seriesList.scrollWidth - seriesList.clientWidth);
    const clampedX = Math.max(newX, maxScrollLeft);

    gsap.to(seriesList, {
      x: clampedX,
      duration: 0.5,
      ease: "power3.out",
      onStart: () => (isAnimating.current = true),
      onComplete: () => (isAnimating.current = false),
      overwrite: true,
    });
  }, 0);

  // Key navigation listener for episode cards
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!document.activeElement.closest("#middlebox")) return;

      const focusableElements = episodeRefs.current;
      const currentIndex = focusableElements.indexOf(document.activeElement);
      
      if (currentIndex === -1 || focusableElements.length === 0) return;

      if (event.key === "ArrowRight" || event.keyCode === 39) {
        if (currentIndex <= focusableElements.length - 1) {
          const nextIndex = currentIndex;
          scrollToEpisode(nextIndex);
          focusableElements[nextIndex].focus();
        }
      } else if (event.key === "ArrowLeft" || event.keyCode === 37) {
        if (currentIndex >= 0) {
          const prevIndex = currentIndex;
          scrollToEpisode(prevIndex);
          focusableElements[prevIndex].focus();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [scrollToEpisode]);

  return (
    <div className="category-row slide-category-row" ref={ref} hidden={hidden}>
      {slideView && <h2 className="category-title fw-bold">{data.category_name}</h2>}
      <div className="series-list-wrapper">
        <div
          className={`series-list ${slideView ? 'd-flex' : ''} ${filterEnabled ? '' : 'grid-layout'}`}
          ref={seriesListRef}
        >
          {data.series.map((series) => (
            <div key={series.episodes[0].episode_id} className={`series ${slideView ? 'series-slide' : ''}`}>
              <div className={`${slideView ? 'd-flex justify-content-center flex-wrap' : ''} ${filterEnabled ? 'grid-layout' : ''}`}>
                {series.is_series ? (
                  <EpisodeCard 
                    episode={series.episodes[0]} 
                    series={series}
                    ref={(el) => el && episodeRefs.current.push(el)} // Push each EpisodeCard ref in order
                  />
                ) : (
                  series.episodes.map((episode) => (
                    <EpisodeCard 
                      key={episode.episode_id} 
                      episode={episode} 
                      series={series}
                      ref={(el) => el && episodeRefs.current.push(el)} // Push each EpisodeCard ref in order
                    />
                  ))
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default CategoryRow;
