// ScrollUtils.js
import { gsap } from "gsap"; // Ensure GSAP is imported

// const darkColors = [
//   "#130e0e", // Dark grey
//   "#000", // Another shade of dark grey
//   "#0e1310", // Blackish grey
//   "#0e1013", // Very dark grey
//   "#100e13", // Another dark shade
//   "#0d0d0d", // Almost black
//   "#130e0e", // Medium dark grey
//   "#050505", // Almost black but slightly grey
// ];

// // Function to get a random color from the darkColors array
// const getRandomDarkColor = () => {
//   const randomIndex = Math.floor(Math.random() * darkColors.length);
//   return darkColors[randomIndex];
// };

export const animateScrollY = (targetElement, ListRef) => {
  if (
    !targetElement ||
    !ListRef.current ||
    typeof targetElement.closest !== "function"
  ) {
    return;
  }

  const List = ListRef.current;
  //const ListHeight = List.clientHeight; // Height of the visible area
  const categoryRow = targetElement.closest(".category-row");

  if (!categoryRow) {
    return;
  }

  // const categoryRowHeight = categoryRow.offsetHeight + parseFloat(window.getComputedStyle(categoryRow).marginBottom);

  // Calculate target translateY position to center the target element
  // const targetOffsetTop = categoryRow.offsetTop; // Distance of the target from the top of the List
  // const targetTranslateY = Math.max(
  //   0,
  //   targetOffsetTop - (ListHeight / 2) + (categoryRowHeight / 2) // Center the target element
  // );

  // Apply the translateY animation with GSAP to the categoryList
  gsap.to(List, {
    yPercent: -10, // Negative value to scroll up
    duration: 0.7,
    ease: "power3.out",
    overwrite: "auto",
    onComplete: () => {
      // Optionally, you could change background color or other styles after scroll
    }
  });
};


// export const animateScrollY = (targetElement, ListRef) => {
//   if (
//     !targetElement ||
//     !ListRef.current ||
//     typeof targetElement.closest !== "function"
//   ) {
//     return;
//   }

//   const List = ListRef.current;
//   const ListHeight = List.clientHeight;
//   const categoryRow = targetElement.closest(".category-row");

//   if (!categoryRow) {
//     return;
//   }

//   const categoryRowHeight =
//     categoryRow.offsetHeight +
//     parseFloat(window.getComputedStyle(categoryRow).marginBottom);

//   const targetScrollTop = Math.max(
//     0,
//     categoryRow.offsetTop - (ListHeight - categoryRowHeight) / 2
//   );

//   const start = List.scrollTop;
//   const change = targetScrollTop - start;
//   const duration = 250;
//   const increment = 20;

//   let currentTime = 0;

//   const easeInOutQuad = (t) => {
//     t /= duration / 2;
//     if (t < 1) return (change / 2) * t * t + start;
//     t--;
//     return (-change / 2) * (t * (t - 2) - 1) + start;
//   };

//   const animate = () => {
//     currentTime += increment;
//     const val = easeInOutQuad(currentTime);
//     List.scrollTop = val;

//     if (currentTime < duration) {
//       requestAnimationFrame(animate);
//     }
//   };

//   animate();
// };

export const animateScrollX = (
  targetPosition,
  scrollPosition,
  seriesListRef,
  setScrollPosition,
  isAnimating,
  speedMultiplier, // New parameter to increase speed
  onAnimationComplete
) => {
  if (isAnimating.current) return;

  isAnimating.current = true;

  const seriesList = seriesListRef.current;
  const maxScrollLeft = -(seriesList.scrollWidth - seriesList.clientWidth);
  const clampedPosition = Math.min(Math.max(targetPosition, maxScrollLeft), 0);

  const start = scrollPosition;
  const change = clampedPosition - start;
  const duration = 300 / speedMultiplier;  // Adjust duration based on speedMultiplier
  const increment = 16;

  let currentTime = 0;

  const easeInOutQuad = (t) => {
    t /= duration / 2;
    if (t < 1) return (change / 2) * t * t + start;
    t--;
    return (-change / 2) * (t * (t - 2) - 1) + start;
  };

  const animate = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime);
    setScrollPosition(val);

    const roundedVal = Math.round(val);
    seriesList.style.transform = `translateX(${roundedVal}px)`;

    if (currentTime < duration) {
      requestAnimationFrame(animate);
    } else {
      isAnimating.current = false;
      onAnimationComplete && onAnimationComplete();  // Trigger the callback when animation completes
    }
  };

  requestAnimationFrame(animate);
};

// export const animateScrollX = (
//   targetPosition,
//   scrollPosition,
//   seriesListRef,
//   setScrollPosition,
//   isAnimating
// ) => {
//   if (isAnimating.current) return;

//   isAnimating.current = true;

//   console.log(document.activeElement);

//   const seriesList = seriesListRef.current;
//   const maxScrollLeft = -(seriesList.scrollWidth - seriesList.clientWidth);
//   const clampedPosition = Math.min(Math.max(targetPosition, maxScrollLeft), 0);

//   const start = scrollPosition;
//   const change = clampedPosition - start;
//   const duration = 200;
//   const increment = 16;

//   let currentTime = 0;

//   const easeInOutQuad = (t) => {
//     t /= duration / 2;
//     if (t < 1) return (change / 2) * t * t + start;
//     t--;
//     return (-change / 2) * (t * (t - 2) - 1) + start;
//   };

//   const animate = () => {
//     currentTime += increment;
//     const val = easeInOutQuad(currentTime);
//     setScrollPosition(val);

//     // Avoid unnecessary reflows by only updating transform
//     seriesList.style.transform = `translateX(${val}px)`;

//     if (currentTime < duration) {
//       requestAnimationFrame(animate);
//     } else {
//       isAnimating.current = false;
//     }
//   };

//   requestAnimationFrame(animate);
// };

export const animateScrollVY = (targetElement, ListRef) => {
  if (!targetElement || !ListRef.current || typeof targetElement.closest !== "function") {
    console.log("Invalid target or list reference");
    return;
  }

  const List = ListRef.current;
  const categoryRow = targetElement.closest(".episodeCard");

  if (!categoryRow) {
    console.log("No matching categoryRow found with .episodeCard class");
    return;
  }

  // Update: Directly use the offsetTop of the categoryRow without adjusting by ListHeight
  const targetScrollTop = categoryRow.offsetTop;
  
  // console.log("categoryRow offsetTop:", categoryRow.offsetTop);
  // console.log("Target scrollTop:", targetScrollTop);

  console.log(targetScrollTop, List.scrollTop);

  const start = List.scrollTop;
  const change = targetScrollTop - start;
  const duration = 250;
  const increment = 20;
  let currentTime = 0;

  const easeInOutQuad = (t) => {
    t /= duration / 2;
    if (t < 1) return (change / 2) * t * t + start;
    t--;
    return (-change / 2) * (t * (t - 2) - 1) + start;
  };

  const animate = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime);

    List.scrollTop = val;
    // console.log("Current scrollTop:", List.scrollTop);

    if (currentTime < duration) {
      requestAnimationFrame(animate);
    }
  };

  animate();
};


export const animateScrollVideosY = (targetElement, listRef) => {
  if (!targetElement || !listRef.current) {
    return;
  }

  const list = listRef.current;
  const listHeight = list.clientHeight;
  const elementOffsetTop = targetElement.offsetTop;
  const elementHeight = targetElement.offsetHeight;

  const targetScrollTop = Math.max(
    0,
    elementOffsetTop - (listHeight - elementHeight) / 2
  );

  const start = list.scrollTop;
  const change = targetScrollTop - start;
  const duration = 250;
  const increment = 20;

  let currentTime = 0;

  const easeInOutQuad = (t) => {
    t /= duration / 2;
    if (t < 1) return (change / 2) * t * t + start;
    t--;
    return (-change / 2) * (t * (t - 2) - 1) + start;
  };

  const animate = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime);
    list.scrollTop = val;

    if (currentTime < duration) {
      requestAnimationFrame(animate);
    }
  };

  animate();
};

export const scrollToElement = (targetElement, listRef) => {
  if (!targetElement || !listRef.current) {
    return;
  }

  const list = listRef.current;
  const targetTop = targetElement.offsetTop - list.offsetTop;
  list.scrollTop = targetTop;
};