import React, { useEffect, useCallback, useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import './EpisodeCard.css';
import { setCurrentWatch } from "../appSlice";
import { useHistory } from "react-router-dom";
import imagePlaceholder from '../../assets/imagePlaceholder.png';

const EpisodeCard = forwardRef(({ episode, series }, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [thumbnailUrl, setThumbnailUrl] = useState(imagePlaceholder);

  useEffect(() => {
    const img = new Image();
    img.onload = function () {
      setThumbnailUrl(img.width < 1280 || img.height < 155 
        ? `https://i.ytimg.com/vi/${episode.video_id}/mqdefault.jpg` 
        : episode.thumbnail_url);
    };
    img.onerror = function () {
      setThumbnailUrl(`https://i.ytimg.com/vi/${episode.video_id}/mqdefault.jpg`);
    };
    img.src = episode.thumbnail_url;
  }, [episode.thumbnail_url, episode.video_id]);

  const goToWatch = useCallback((selectedEpisode) => {
    dispatch(setCurrentWatch({ series, selectedEpisode, data: null, isPlaying: false, isPlayerReady: false }));
    history.push(`/watch/${series.series_id}/${selectedEpisode.episode_id}`);
  }, [dispatch, history, series]);

  return (
    <div 
      ref={ref} // Use the forwarded ref here
      className="episodeCard focusable mb-4" 
      data-title={episode.episode_title} 
      data-videoid={episode.video_id}
      onClick={() => goToWatch(episode)}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
          goToWatch(episode);
        }
      }}
      tabIndex={0}
    >
      {series.is_series && (
        <div className="episodeTag">
          {series.title}
        </div>
      )}
      <img 
        src={thumbnailUrl} 
        alt={episode.episode_title} 
        loading="lazy" 
      />
    </div>
  );
});

export default React.memo(EpisodeCard);
