// src/components/SplashScreen.js
import React, { useEffect, useState } from 'react';
import './SplashScreen.css'; // Create this CSS file to style the splash screen
import logo from "../../assets/icons8-play-100.png";

const SplashScreen = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 1000); // 5 seconds

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  return isVisible ? (
    <div className="splash-screen">
      <img src={logo} alt='Tamiloodai' />
      <h1>தமிழ் ஓடை</h1>      
    </div>
  ) : null;
};

export default SplashScreen;
