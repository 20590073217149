import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataAsync, selectApp, setData } from "../appSlice";
import ListCategories from "../ListCategories/ListCategories";
import Loading from "../Loading";
import { useHistory } from "react-router-dom";
import SpatialNavigation from "spatial-navigation-js";
import useSpatialNavigation from "./SpatialNavigationWrapperHome";

function Home() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, status, data } = useSelector(selectApp);
  const categoryListRef = useRef(null);
  
  useSpatialNavigation();

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const result = await dispatch(fetchDataAsync("/home.php"));
        dispatch(setData(result));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataFromApi();

    // Configure spatial navigation
    SpatialNavigation.disable("watch-sidebar");
    SpatialNavigation.disable("middlebox-movies");
    SpatialNavigation.disable("playbackControls");
    SpatialNavigation.enable("middlebox");

    return () => {
      SpatialNavigation.remove("middlebox");
    };
    
  }, [dispatch]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (history.action === "POP") {
        history.replace("/");
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <div className="main-container">
      {isLoading && <Loading />}
      {status === "succeeded" && (
        <ListCategories
          categoryListRef={categoryListRef}
          categories={data}
          spatialElementId="middlebox"
          slideView={true}
        />
      )}
      {status === "failed" && <p>Error loading data</p>}
    </div>
  );
}

export default Home;
