import React, { useEffect, useRef } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import NavBar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import GridPage from './components/GridPage/GridPage';
import SplashScreen from './components/SplashScreen/SplashScreen';
import Watch from './components/Watch/Watch';
import PlayerScreen from './Player';
import { useSelector } from 'react-redux';
import { selectApp } from './components/appSlice';

function App() {
  const playerRef = useRef(null);

  useEffect(() => {
    const navbar = document.querySelector(".header");
    const navHeight = navbar?.offsetHeight || 0;
    document.documentElement.style.setProperty("--navbar-height", `${navHeight}px`);
  }, []);
  

  return (
    <div className="App">
      {<SplashScreen />}
      
      {/* Make sure PlayerScreen is wrapped within the Router */}
      <Router>
        <PlayerScreen playerRef={playerRef} />
        <RouteContainer playerRef={playerRef}/>
      </Router>
    </div>
  );
}

function RouteContainer({ playerRef }) {
  const { isPlaying, isPlayerReady } = useSelector(selectApp).currentWatch;
  return (
    <Switch>
      {/* Home Route */}
      <Route
        exact
        path="/"
        render={() => (
          <>
            {!isPlaying && !isPlayerReady && (
              <>
                <NavBar />
                <Home />
              </>
            )}
          </>
        )}
      />

      {/* Shows Route */}
      <Route
        path="/shows"
        render={() => (
          <>
            {!isPlaying && !isPlayerReady && (
              <>
                <NavBar />
                <GridPage category="shows" filterEnabled={true} />
              </>
            )}
          </>
        )}
      />

      {/* Kids Route */}
      <Route
        path="/kids"
        render={() => (
          <>
            {!isPlaying && !isPlayerReady && (
              <>
                <NavBar />
                <GridPage category="kids" filterEnabled={false} />
              </>
            )}
          </>
        )}
      />

      {/* Movies Route */}
      <Route
        path="/movies"
        render={() => (
          <>
            {!isPlaying && !isPlayerReady && (
              <>
                <NavBar />
                <GridPage category="movies" filterEnabled={true} />
              </>
            )}
          </>
        )}
      />

      {/* Songs Route */}
      <Route
        path="/songs"
        render={() => (
          <>
            {!isPlaying && !isPlayerReady && (
              <>
                <NavBar />
                <GridPage category="songs" filterEnabled={true} />
              </>
            )}
          </>
        )}
      />

      {/* Watch Route */}
      <Route
        path="/watch"
        render={() => {
          return <Watch playerRef={playerRef} />;
        }}
      />
    </Switch>
  );
}

export default App;
