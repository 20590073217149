import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    isLoading: true,
    isPageHome: true,
    status: 'idle',
    data: [],
    selectedFilterCategory: null,
    currentWatch: {
      series: null,
      selectedEpisode: {video_id: null},
      data: null,
      isPlaying: false,
      isMuted: false,
      volume: 100,
      isPlayerReady: false,
      seeking: false,
      showSidebar: false,
      isFocusedOnControls: false,
      currentTime: 0,
      isVideoInCue: false,
      isProgressBarVisible: true
    },
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setIsPageHome: (state, action) => {
      state.isPageHome = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setCurrentWatch: (state, action) => {
      state.currentWatch = Object.assign({}, state.currentWatch, action.payload);
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setSelectedFilterCategory: (state, action) => {
      state.selectedFilterCategory = action.payload
    }
  },
})

export const { setLoading, setIsPageHome, setStatus, setData, setCurrentWatch, setSelectedFilterCategory } = appSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

const baseUrl = "https://velapps.com/tamiloodai/backend/php/api";

export const fetchDataAsync = (endpoint) => async (dispatch) => {
  dispatch(setStatus('loading'));
  dispatch(setLoading(true));
  
  try {
    const response = await fetch(`${baseUrl}/${endpoint}`, {
      method: 'GET', // or 'POST', etc.
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const data = await response.json();
    
    // Log data for debugging
    //console.log(data);
    
    dispatch(setStatus('succeeded'));
    dispatch(setLoading(false));
    
    // Return data
    return data;
    
  } catch (error) {
    dispatch(setStatus('failed'));
    dispatch(setLoading(false));
    
    // Log the error
    console.error('Fetch data async error:', error);
    
    // Re-throw the error to be caught in the component
    throw error;
  }
};


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectApp = (state) => state.app

export default appSlice.reducer
