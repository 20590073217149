import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Player.css";
import { useDispatch, useSelector } from "react-redux";
import { selectApp, setCurrentWatch } from "./components/appSlice";
import { useLocation } from "react-router-dom";
import Sidebar from "./components/Watch/Sidebar";
import PlaybackControls from "./components/Watch/PlaybackControls";

const PlayerScreen = ({ playerRef }) => {
  const { selectedEpisode, isPlaying, data, isPlayerReady, isFocusedOnControls, showSidebar, isProgressBarVisible } =
    useSelector(selectApp).currentWatch;
  const [isAPIReady, setIsAPIReady] = useState(false);
  const [videoInCue, setVideoInCue] = useState(false);
  const [isVideoEndState, setIsVideoEndState] = useState(false);
  const [progress, setProgress] = useState(0); // Percentage of the video watched
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0); // Total video duration in seconds
  const dispatch = useDispatch();
  const location = useLocation();
  const hideTimerRef = useRef(null);

  // Wait for the YouTube API to be ready
  useEffect(() => {
    const onYouTubeIframeAPIReady = () => {
      setIsAPIReady(true);
    };

    // Check if YT API is already loaded
    if (window.YT) {
      setIsAPIReady(true);
    } else {
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }
  }, []);

  // Load the video when the selected episode changes
  useEffect(() => {
    if (playerRef.current && selectedEpisode.video_id) {
      const resume = localStorage.getItem(selectedEpisode.video_id);
      playerRef.current.cueVideoById({
        videoId: selectedEpisode.video_id,
        startSeconds: resume,
      });
      setVideoInCue(true);
    }
  }, [selectedEpisode, playerRef]);

  // Handle video end state and auto-play the next episode
  useEffect(() => {
    if (isVideoEndState) {
      localStorage.removeItem(selectedEpisode.video_id);

      const currentWatchId = selectedEpisode.episode_id;
      const nextWatchIndex =
        data.episodes?.findIndex(
          (episode) => parseInt(episode.episode_id) === parseInt(currentWatchId)
        ) + 1;

      if (nextWatchIndex !== -1 && data.episodes.length > nextWatchIndex) {
        const nextEpisode = data.episodes[nextWatchIndex];
        dispatch(
          setCurrentWatch({ selectedEpisode: nextEpisode, isPlaying: false })
        );
      }

      setIsVideoEndState(false);
    }
  }, [selectedEpisode, data, isVideoEndState, dispatch]);

  // Preload YouTube iframe when the component mounts
  useEffect(() => {
    if (isAPIReady && !playerRef.current) {
      // Initialize the YouTube player
      playerRef.current = new window.YT.Player("player-screen", {
        videoId: "", // No video initially
        playerVars: {
          autoplay: 0,
          controls: 0,
          mute: 0,
          modestbranding: 1, // Reduce YouTube branding
          disablekb: 1, // Disable keyboard controls
          showinfo: 0, // Hide video information
          iv_load_policy: 3, // Hide video annotations
          rel: 0, // Prevent related videos from showing
          ecver: 2,
        },
        events: {
          onReady: (event) => {
            console.log("YouTube Player is ready");
          },
          onStateChange: (event) => {
            const player = playerRef.current;
            if (
              event.data === window.YT.PlayerState.CUED &&
              window.location.href.includes("watch")
            ) {
              // Video is cued and ready, now we can safely play it
              playerRef.current.playVideo();
              dispatch(
                setCurrentWatch({ isPlaying: true, isPlayerReady: true })
              );
            } else if (event.data === window.YT.PlayerState.PLAYING) {
              const interval = setInterval(() => {
                if (player && player.getCurrentTime && player.getDuration) {
                  const currentTime = player.getCurrentTime();
                  const totalDuration = player.getDuration();
                  setProgress((currentTime / totalDuration) * 100); // Progress in percentage
                  setDuration(totalDuration);
                  setCurrentTime(currentTime);
                }
              }, 1000); // Update every second

              return () => clearInterval(interval); // Clean up interval on unmount
            } else if (event.data === window.YT.PlayerState.ENDED) {
              setIsVideoEndState(true);
            }
          },
        },
      });
    }
  }, [
    dispatch,
    playerRef,
    isAPIReady,
    selectedEpisode.video_id,
    isVideoEndState,
  ]);

  useEffect(() => {
    if(isPlayerReady && currentTime !== 0 && !isVideoEndState && currentTime < (duration-20) && data?.category_id !== "1")
      localStorage.setItem(selectedEpisode.video_id, currentTime);
  }, [isPlayerReady, currentTime, selectedEpisode.video_id, isVideoEndState, duration, data?.category_id]);
  
  useEffect(() => {
    if (playerRef.current && playerRef.current.stopVideo) {
      if (!location.pathname.includes("watch")) {
        playerRef.current.stopVideo();
        playerRef.current.clearVideo();
        dispatch(setCurrentWatch({ isPlaying: false, isPlayerReady: false, showSidebar: false }));
        setVideoInCue(false);
      }
    }
  }, [dispatch, location, playerRef, selectedEpisode.video_id]);

  const handlePlayPause = useCallback(() => {
    const player = playerRef.current;
    if (player && player.playVideo && player.pauseVideo) {
      if (isPlaying) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }
      dispatch(setCurrentWatch({ isPlaying: !isPlaying }));
    }
  }, [isPlaying, dispatch, playerRef]);

  const resetHideTimer = useCallback(() => {
    dispatch(setCurrentWatch({ isProgressBarVisible: true}));

    if (hideTimerRef.current) clearTimeout(hideTimerRef.current);

    hideTimerRef.current = setTimeout(() => {
      dispatch(setCurrentWatch({ isProgressBarVisible: false}));
    }, 5000);
  }, [dispatch]);

  // Handle keydown events for seeking and play/pause
  useEffect(() => {
    const handleKeyDown = (e) => {
      const player = playerRef.current;
      if (!player || !isPlayerReady) return; // Prevent keydown actions if not ready or not playing

      // Avoid action if Alt + ArrowLeft is pressed
      if (e.altKey && e.key === "ArrowLeft") return;

      if (((e.key === "ArrowDown" || e.keyCode === 40) || (e.key === "ArrowUp" || e.keyCode === 38)) && !showSidebar) {
        resetHideTimer();
        return;
      }
      
      // Check if not focused on controls
      if (!isFocusedOnControls && !showSidebar) {
        if (e.key === "Enter" || e.keyCode === 13) {
          handlePlayPause();
          //dispatch(setCurrentWatch({isFocusedOnControls: true}));
        } else if (e.key === "ArrowLeft" || e.keyCode === 37) {
          dispatch(setCurrentWatch({ showSidebar: false }));
          if(isProgressBarVisible) {
            player.pauseVideo();
            // const currentTime = player.getCurrentTime(); // Get current time
            // const duration = player.getDuration(); // Get video duration
            // const seekTime = currentTime - (duration * 0.01); // Calculate 2% of the video duration
            // player.seekTo(Math.max(seekTime, 0));
      
            player.seekTo(player.getCurrentTime() - 25); // Seek back 5 seconds
            setTimeout(() => {
              player.playVideo();
            }, 700);
          }
          
        } else if ((e.key === "ArrowRight" || e.keyCode === 39) && !showSidebar) {
          dispatch(setCurrentWatch({ showSidebar: false }));
          
          if(isProgressBarVisible) {
            player.pauseVideo();

            // const currentTime = player.getCurrentTime(); // Get current time
            // const duration = player.getDuration(); // Get video duration
            // const seekTime = currentTime + (duration * 0.02);
            // player.seekTo(Math.max(seekTime, 0));
            
            player.seekTo(player.getCurrentTime() + 50); // Seek forward 5 seconds
            setTimeout(() => {
              player.playVideo();
            }, 700);
          }
        }
      }
    };

    // Add the listener only when the path includes "/watch"
    if (location.pathname.includes("/watch") && isPlayerReady) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, resetHideTimer, handlePlayPause, isProgressBarVisible, showSidebar, isPlayerReady, isFocusedOnControls, playerRef, location.pathname]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format to ensure two digits for minutes and seconds
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

    // If duration has hours, display it; otherwise, only show minutes and seconds
    if (hours > 0) {
      return `${hours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedMinutes}:${formattedSeconds}`;
    }
  };

  return (
    <div
      className={`videoWrapper ${
        isPlayerReady && videoInCue ? `d-block` : "d-none"
      }`}
    >
      { <Sidebar selectedEpisode={selectedEpisode} /> }
      <div id="player-screen" className="fullscreen no-pointer-events" />

      {/* Render PlaybackControls only if the sidebar is not shown */}
      {!showSidebar && (
        <PlaybackControls onPlayPause={handlePlayPause} playerRef={playerRef} />
      )}

      <div className={`progress-bar-container ${isProgressBarVisible ? "visible" : "hidden"}`}
      >
        {/* Progress Bar */}
        <div className="progress-bar" style={{ width: `${progress}%` }} />

        {/* Time Display */}
        <div className="time-display">
          {/* Left: Current Time (Watched) */}
          <span className="current-time">
            {formatTime(playerRef.current?.getCurrentTime?.() || 0)}
          </span>

          {/* Right: Total Duration */}
          <span className="total-duration">{formatTime(duration)}</span>
        </div>
      </div>
    </div>
  );
};

export default PlayerScreen;
