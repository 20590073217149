import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './app/store';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';

// Load YouTube IFrame Player API asynchronously
// const loadYouTubeIframeAPI = () => {
//   return new Promise((resolve) => {
//     const existingScript = document.getElementById('youtube-iframe-api');
    
//     if (!existingScript) {
//       const tag = document.createElement('script');
//       tag.src = 'https://www.youtube.com/iframe_api';
//       tag.id = 'youtube-iframe-api';
//       document.body.appendChild(tag);

//       tag.onload = () => {
//         resolve();
//       };
//     } else {
//       resolve(); // Already loaded
//     }
//   });
// };

// loadYouTubeIframeAPI();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// serviceWorker.unregister();
