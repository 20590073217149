import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectApp, setCurrentWatch } from "../appSlice";

const Sidebar = ({ selectedEpisode }) => {
  const dispatch = useDispatch();
  const focusableContainerRef = useRef(null);
  const sidebarTimeoutRef = useRef(null); 
  const lastFocusedElementRef = useRef(null); // Store the last focused element
  const lastScrollPositionRef = useRef(0); // Store the last scroll position

  const { episode_id } = useSelector(selectApp).currentWatch.selectedEpisode;
  const { isPlayerReady, showSidebar, data, isFocusedOnControls, isProgressBarVisible } = useSelector(selectApp).currentWatch;

  const resetSidebarTimeout = useCallback(() => {
    if (sidebarTimeoutRef.current) {
      clearTimeout(sidebarTimeoutRef.current);
    }
    sidebarTimeoutRef.current = setTimeout(() => {
      lastScrollPositionRef.current = focusableContainerRef.current.scrollTop;
      lastFocusedElementRef.current = document.activeElement;
      dispatch(setCurrentWatch({ showSidebar: false, isFocusedOnControls: false }));
    }, 4700);
  }, [dispatch]);

  useEffect(() => {
    const handleInteraction = (event) => {
      if (event.target.closest('#playbackControls') || isProgressBarVisible) return;

      if ((event.key === "ArrowLeft" || event.keyCode === 37) && !showSidebar && !isFocusedOnControls) {
        dispatch(setCurrentWatch({ showSidebar: true, isFocusedOnControls: false }));
        resetSidebarTimeout();
      } else if (((event.key === "ArrowRight" || event.keyCode === 39)) && showSidebar) {
        lastScrollPositionRef.current = focusableContainerRef.current.scrollTop;
        lastFocusedElementRef.current = document.activeElement;
        dispatch(setCurrentWatch({ showSidebar: false }));
        clearTimeout(sidebarTimeoutRef.current);
      }
      // Reset the sidebar timeout on other key events while sidebar is open
      else if (showSidebar) {
        resetSidebarTimeout(); // Reset timeout for other keys when sidebar is open
      }

      //resetSidebarTimeout();
    };

    if (isPlayerReady) {
      window.addEventListener("keydown", handleInteraction);
    } else {
      window.removeEventListener("keydown", handleInteraction);
    }

    return () => {
      window.removeEventListener("keydown", handleInteraction);
    };
  }, [dispatch, showSidebar, isPlayerReady, isFocusedOnControls, isProgressBarVisible, resetSidebarTimeout]);

  const handleEpisodeSelect = useCallback((episode) => {
    if (episode_id !== episode.episode_id && showSidebar) {
      dispatch(setCurrentWatch({ selectedEpisode: episode, isPlayerReady: false, isPlaying: false, showSidebar: false }));
    }
  }, [dispatch, episode_id, showSidebar]);

  useEffect(() => {
    if (showSidebar) {
      lastFocusedElementRef.current?.focus(); // Restore focus to the last focused element
    }
  }, [showSidebar]);
  

  const handleKeyDown = useCallback((event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      const focusedElement = document.activeElement;
      if (focusedElement && focusedElement.classList.contains("focusable")) {
        const episode = data?.episodes.find(
          (ep) => ep.episode_id.toString() === focusedElement.dataset.episodeid
        );
        if (episode) {
          handleEpisodeSelect(episode);
        }
      }
    }
  }, [data?.episodes, handleEpisodeSelect]);

  const isElementInCenter = useCallback((element) => {
    const container = focusableContainerRef.current;
    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    const containerCenter = containerRect.top + containerRect.height / 2;
    const elementCenter = elementRect.top + elementRect.height / 2;

    const centerRegionTop = containerCenter - containerRect.height / 4;
    const centerRegionBottom = containerCenter + containerRect.height / 4;

    return elementCenter >= centerRegionTop && elementCenter <= centerRegionBottom;
  }, []);

  const handleScrollY = useCallback(
    (direction) => {
      if (focusableContainerRef.current) {
        const container = focusableContainerRef.current;
        const focusableElements = container.querySelectorAll(".focusable");
        const currentElement = document.activeElement;
        const currentIndex = Array.from(focusableElements).indexOf(currentElement);

        if (currentIndex !== -1) {
          let targetElement;
          if (direction === "down" && currentIndex < focusableElements.length - 1) {
            targetElement = focusableElements[currentIndex + 1];
          } else if (direction === "up" && currentIndex > 0) {
            targetElement = focusableElements[currentIndex - 1];
          }

          if (targetElement && !isElementInCenter(targetElement)) {
            const elementRect = targetElement.getBoundingClientRect();
            const elementHeight = elementRect.height;

            if (direction === "down") {
              container.scrollTop += elementHeight;
            } else if (direction === "up") {
              container.scrollTop -= elementHeight;
            }
          }
        }
      }
    },
    [isElementInCenter]
  );

  // Restore the last scroll position and focus the last focused element or the active element
  useLayoutEffect(() => {
    if (showSidebar && focusableContainerRef.current) {
      const container = focusableContainerRef.current;

      // Restore the last scroll position
      container.scrollTop = lastScrollPositionRef.current;

      // Focus the last focused element if it exists
      if (lastFocusedElementRef.current) {
        lastFocusedElementRef.current.focus();
      } else {
        const activeElement = container.querySelector(".active");
        if (activeElement) {
          activeElement.focus();

          // Center the active element in view
          const elementRect = activeElement.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();
          container.scrollTop += elementRect.top - (containerRect.top + containerRect.height / 2 - elementRect.height / 2);
        }
      }
    }
  }, [showSidebar]);

  useEffect(() => {
    if(!isPlayerReady) {
      lastFocusedElementRef.current = null;
      lastScrollPositionRef.current = 0;
    }  
  }, [episode_id, isPlayerReady])
  

  useLayoutEffect(() => {
    const handleGlobalKeyDown = (event) => {
      const focusableElements = focusableContainerRef.current?.querySelectorAll(".focusable");
      const currentIndex = Array.from(focusableElements).indexOf(document.activeElement);

      if (currentIndex !== -1) {
        if (event.key === "ArrowDown" && currentIndex < focusableElements.length - 1) {
          const nextElement = focusableElements[currentIndex];
          if (nextElement) {
            nextElement.focus();
            handleScrollY("down");
          }
        }

        if (event.key === "ArrowUp" && currentIndex > 0) {
          const prevElement = focusableElements[currentIndex];
          if (prevElement) {
            prevElement.focus();
            handleScrollY("up");
          }
        }
      }
    };

    if (showSidebar) {
      window.addEventListener("keydown", handleGlobalKeyDown);
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleGlobalKeyDown);
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleScrollY, handleKeyDown, showSidebar]);

  return (
    <div id="sidebar" className={`sidebar-container ${showSidebar && isPlayerReady ? 'd-block' : 'd-none'}`}>
      <div
        className="spatial-section focusable-container d-flex flex-column"
        ref={focusableContainerRef}
      >
        {data && data?.episodes && data.episodes.map((episode, index) => (
          <div
            className={`focusable d-flex align-items-center p-2 row ${
              selectedEpisode &&
              selectedEpisode.episode_id.toString() ===
                episode.episode_id.toString()
                ? "active"
                : ""
            }`}
            key={index}
            data-title={episode.title}
            data-videoid={episode.video_id}
            data-episodeid={episode.episode_id} 
            onClick={() => handleEpisodeSelect(episode)}
            tabIndex={0}
          >
            <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-5">
              <img
                src={`https://i.ytimg.com/vi/${episode.video_id}/mqdefault.jpg`}
                alt={episode.episode_title}
              />
            </div>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-7">
              {episode.episode_title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
